import { dataTypes } from '@common-packages/shared-constants';
import { DRILL_TYPE } from '@common-packages/drilling';

import {
  defaultColumnDefinition,
  adminColumnDefinition,
  defaultNoDataColumn,
  selectCellEditorParamsFactory,
} from '../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../shared/columnDefinitions/cellRenderers';
import {
  getEditorSelector,
  getDataTypeBasedCellRenderer,
} from '../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import { currencyCellFormatter } from '../shared/formatters';
import { dataTypes as ColumnTypes } from '../shared/constants';
import { defaultCustomOptionCellSelector } from '../shared/columnDefinitions/cellEditor';

import GridHeader from './gridHeader';

const getDeleteIconOptions = ({ systemDefined, pending }, deleteRow) => {
  if (pending) {
    return {
      shouldShowDeleteIcon: () => false,
    };
  }

  if (systemDefined) {
    return {
      shouldShowDeleteIcon: () => true,
      shouldDisableDeleteIcon: () => true,
      customToolTipMessage: 'This is a system-defined dataset, instances cannot be deleted',
      customIcon: 'desktop',
    };
  }

  return {
    shouldShowDeleteIcon: () => true,
    onDeleteIconClick: deleteRow,
  };
};

const validators = {
  year: (value, data) => {
    const maxLength = data?.dataItemMetadata?.year.dataLength;
    if (maxLength === 0) {
      return; // unlimited length
    }
    if (value?.length > maxLength) {
      return `Value's length should be less than or equal to ${maxLength}`;
    }
  },
};

const getColumnDefinitions = ({
  columnsBlueprint,
  isInEditMode,
  updateRow,
  deleteRow,
  datasetDefinition,
  context,
}) => {
  // fallback for no data to show user "no rows to show"
  if (!columnsBlueprint.length) {
    return [defaultNoDataColumn];
  }

  const getCheckboxGroupValue = ({ value: rowValue, data }, checkboxGroupItems) =>
    checkboxGroupItems.find(({ value }) => rowValue === value || data[value])?.label;

  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: () => false,
    ...getDeleteIconOptions(datasetDefinition, deleteRow),
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    ...columnsBlueprint.map(
      ({
        dataType,
        displayName,
        field,
        dataItemDefinitionName,
        isEditable,
        drillDestination,
        selectionListId,
        selectionListItems,
        checkboxGroupItems,
      }) => ({
        ...defaultColumnDefinition,
        valueFormatter: dataType === dataTypes.CURRENCY && currencyCellFormatter,
        cellStyle: {
          textAlign:
            dataType === dataTypes.STRING || dataType === ColumnTypes.CHECKBOX_GROUP
              ? 'left'
              : 'right',
        },
        headerName: displayName,
        headerComponent: GridHeader,
        headerComponentParams: {
          displayName,
          dataItemName: dataItemDefinitionName,
        },
        valueSetter: ({ data, newValue }) => {
          if (dataType === ColumnTypes.CHECKBOX_GROUP) {
            if (data.dataItemMetadata) {
              Object.keys(data.dataItemMetadata).forEach(dataItemDefinitionId => {
                if (
                  typeof data[dataItemDefinitionId] === 'boolean' &&
                  checkboxGroupItems.map(({ value }) => value).includes(dataItemDefinitionId)
                ) {
                  data[dataItemDefinitionId] = newValue === dataItemDefinitionId;
                }
              });
            } else {
              data[newValue] = true;
            }
          } else {
            data[field] = newValue;
          }

          return true;
        },
        cellRenderer: row =>
          getDataTypeBasedCellRenderer({
            context,
            dataType,
            validators,
            valueFormatted:
              dataType === ColumnTypes.CHECKBOX_GROUP
                ? getCheckboxGroupValue(row, checkboxGroupItems)
                : null,
            getDrillData: () =>
              !isInEditMode &&
              drillDestination && {
                drillType: DRILL_TYPE.MULT_GRID,
                parentDatasetDefinitionId: drillDestination.datasetDefinitionId,
                datasetDefinitionId: drillDestination.childDatasetDefId,
                initialParentDatasetInstanceId: row.data.datasetInstanceId,
              },
          })(row),
        cellEditorSelector: row => {
          if (selectionListId || checkboxGroupItems?.length) {
            return {
              ...defaultCustomOptionCellSelector,
              params: selectCellEditorParamsFactory(
                selectionListId ? selectionListItems : checkboxGroupItems,
                {},
                true,
              )(),
            };
          }
          return getEditorSelector(dataType, validators)(row);
        },
        onCellValueChanged: ({ data }) => updateRow(data),
        editable: isInEditMode && isEditable && dataType !== dataTypes.BOOLEAN,
        cellRendererParams: {
          disabled: !isInEditMode,
        },
        field,
        width: 200,
      }),
    ),
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
